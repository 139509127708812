import {BaseWebsiteResource} from "./base-website-resource";
import {Course} from "./course";

export class RegistrationConfig extends BaseWebsiteResource {

  public requireAgbAcceptanceOnRegistration: boolean;

  public requestPaymentInformationForCourseRegistration: boolean
  public carTheoryFlexibleOffered: boolean;

  public carTheoryIntensiveCourse: Course | null;
  public carTheoryIntensiveCourseRequired: boolean;

  public carTheoryAndPracticeIntensiveCourse: Course | null;
  public carTheoryAndPracticeIntensiveCourseRequired: boolean;

  public motorcycleTheoryFlexibleOffered: boolean;

  public motorcycleTheoryIntensiveCourse: Course | null;
  public motorcycleTheoryIntensiveCourseRequired: boolean;

  public motorcycleTheoryAndPracticeIntensiveCourse: Course | null;
  public motorcycleTheoryAndPracticeIntensiveCourseRequired: boolean;

  public truckTheoryFlexibleOffered: boolean;

  public truckTheoryIntensiveCourse: Course | null;
  public truckTheoryIntensiveCourseRequired: boolean;

  public truckTheoryAndPracticeIntensiveCourse: Course | null;
  public truckTheoryAndPracticeIntensiveCourseRequired: boolean;

  constructor(el: any = {}) {
    super(el);

    this.requireAgbAcceptanceOnRegistration = el.requireAgbAcceptanceOnRegistration ?? false;

    this.requestPaymentInformationForCourseRegistration = el.requestPaymentInformationForCourseRegistration ?? true;
    this.carTheoryIntensiveCourse = el.carTheoryIntensiveCourse ? new Course(el.carTheoryIntensiveCourse) : null;
    this.carTheoryIntensiveCourseRequired = el.carTheoryIntensiveCourseRequired ?? false;

    this.carTheoryAndPracticeIntensiveCourse = el.carTheoryAndPracticeIntensiveCourse ? new Course(el.carTheoryAndPracticeIntensiveCourse) : null;
    this.carTheoryAndPracticeIntensiveCourseRequired = el.carTheoryAndPracticeIntensiveCourseRequired ?? false;

    this.motorcycleTheoryIntensiveCourse = el.motorcycleTheoryIntensiveCourse ? new Course(el.motorcycleTheoryIntensiveCourse) : null;
    this.motorcycleTheoryIntensiveCourseRequired = el.motorcycleTheoryIntensiveCourseRequired ?? false;

    this.motorcycleTheoryAndPracticeIntensiveCourse = el.motorcycleTheoryAndPracticeIntensiveCourse ? new Course(el.motorcycleTheoryAndPracticeIntensiveCourse) : null;
    this.motorcycleTheoryAndPracticeIntensiveCourseRequired = el.motorcycleTheoryAndPracticeIntensiveCourseRequired ?? false;

    this.truckTheoryIntensiveCourse = el.truckTheoryIntensiveCourse ? new Course(el.truckTheoryIntensiveCourse) : null;
    this.truckTheoryIntensiveCourseRequired = el.truckTheoryIntensiveCourseRequired ?? false;

    this.truckTheoryAndPracticeIntensiveCourse = el.truckTheoryAndPracticeIntensiveCourse ? new Course(el.truckTheoryAndPracticeIntensiveCourse) : null;
    this.truckTheoryAndPracticeIntensiveCourseRequired = el.truckTheoryAndPracticeIntensiveCourseRequired ?? false;

    this.carTheoryFlexibleOffered = el.carTheoryFlexibleOffered ?? false;
    this.motorcycleTheoryFlexibleOffered = el.motorcycleTheoryFlexibleOffered ?? false;
    this.truckTheoryFlexibleOffered = el.truckTheoryFlexibleOffered ?? false;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      requireAgbAcceptanceOnRegistration: this.requireAgbAcceptanceOnRegistration,

      requestPaymentInformationForCourseRegistration: this.requestPaymentInformationForCourseRegistration,

      carTheoryIntensiveCourse: this.carTheoryIntensiveCourse?.toJson() ?? null,
      carTheoryIntensiveCourseRequired: this.carTheoryIntensiveCourseRequired,

      carTheoryAndPracticeIntensiveCourse: this.carTheoryAndPracticeIntensiveCourse?.toJson() ?? null,
      carTheoryAndPracticeIntensiveCourseRequired: this.carTheoryAndPracticeIntensiveCourseRequired,

      motorcycleTheoryIntensiveCourse: this.motorcycleTheoryIntensiveCourse?.toJson() ?? null,
      motorcycleTheoryIntensiveCourseRequired: this.motorcycleTheoryIntensiveCourseRequired,

      motorcycleTheoryAndPracticeIntensiveCourse: this.motorcycleTheoryAndPracticeIntensiveCourse?.toJson() ?? null,
      motorcycleTheoryAndPracticeIntensiveCourseRequired: this.motorcycleTheoryAndPracticeIntensiveCourseRequired,

      truckTheoryIntensiveCourse: this.truckTheoryIntensiveCourse?.toJson() ?? null,
      truckTheoryIntensiveCourseRequired: this.truckTheoryIntensiveCourseRequired,

      truckTheoryAndPracticeIntensiveCourse: this.truckTheoryAndPracticeIntensiveCourse?.toJson() ?? null,
      truckTheoryAndPracticeIntensiveCourseRequired: this.truckTheoryAndPracticeIntensiveCourseRequired,

      carTheoryFlexibleOffered: this.carTheoryFlexibleOffered,
      motorcycleTheoryFlexibleOffered: this.motorcycleTheoryFlexibleOffered,
      truckTheoryFlexibleOffered: this.truckTheoryFlexibleOffered,
    };
  }

  public override clone(): RegistrationConfig {
    return new RegistrationConfig({...this.toJson(), id: undefined});
  }
}
