import {BaseWebsiteResource} from './base-website-resource';
import {Sortable} from './sortable';
import {ApogeeFile} from './apogee-file';

const types = [
  'CAR',
  'CAR_TRAILER',
  'MOTORCYCLE',
  'TRUCK',
  'BUS',
  'TRACTOR',
  'COURSE',
  'TEAM',
  'LOCATION',
  'CONTACT',
    'DOCUMENT',
    'PRICE'
] as const;

export const menuIconTypes = [...types];

export const menuIconLabels: {[key in MenuIcon]: string} = {
  COURSE: 'Kurs',
  TRACTOR: 'Traktor',
  CAR_TRAILER: 'Auto-Anhänger',
  CAR: 'Auto',
  PRICE: "Preis",
  DOCUMENT: "Document/Antrag",
  MOTORCYCLE: 'Motorrad',
  BUS: 'Bus',
  TRUCK: 'LKW',
  TEAM: 'Team',
  LOCATION: 'Standort',
  CONTACT: 'Kontakt',
};

export type MenuIcon = (typeof menuIconTypes)[number];

export class Menu extends BaseWebsiteResource implements Sortable<Menu> {
  public title: string;
  public displayTitle: string;
  public type: 'PAGE' | 'LINK' | 'CONTAINER';
  public uriSegment: string;
  public parentMenu: Menu | null;
  public sortOrder: number;
  public menuIcon: MenuIcon | undefined;
  public primaryImage: ApogeeFile;
  public showConfiguratorBannerInMegaMenu: boolean;
  public hideInMainMenu: boolean;
  public condensedChildrenStyle: boolean;

  constructor(el: any = {}) {
    super(el);
    this.title = el.title ?? '';
    this.displayTitle = el.displayTitle ?? '';
    this.type = el.type ?? 'LINK';
    this.uriSegment = el.uriSegment ?? '';
    this.parentMenu = el.parentMenu ? new Menu(el.parentMenu) : null;

    this.sortOrder = el.sortOrder || 0;
    this.menuIcon = el.menuIcon;
    this.primaryImage = new ApogeeFile(el.primaryImage);

    this.showConfiguratorBannerInMegaMenu =
      el.showConfiguratorBannerInMegaMenu || false;
    this.hideInMainMenu = el.hideInMainMenu || false;
    this.condensedChildrenStyle = el.condensedChildrenStyle || false;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      displayTitle: this.displayTitle,
      type: this.type,
      uriSegment: this.uriSegment,
      parentMenu: this.parentMenu?.toJson() || null,
      sortOrder: this.sortOrder,
      menuIcon: this.menuIcon,
      primaryImage: this.primaryImage,
      showConfiguratorBannerInMegaMenu: this.showConfiguratorBannerInMegaMenu,
      hideInMainMenu: this.hideInMainMenu,
      condensedChildrenStyle: this.condensedChildrenStyle,
    };
  }

  public get pageUrl(): string {
    const segments = [this.uriSegment];

    let menu = this.parentMenu;
    while (menu) {
      segments.push(menu.uriSegment);
      menu = menu.parentMenu;
    }

    return ('/' + segments.reverse().join('/')).replace(/\/+/g, '/');
  }

  override getLabel(): string {
    return `${this.title} (${this.pageUrl})`;
  }

  public readonly isSortable = true;

  getComparator(): (a: Menu, b: Menu) => number {
    return (a, b) => b.sortOrder - a.sortOrder;
  }

  public override clone(): Menu {
    return new Menu({...this.toJson(), id: undefined});
  }
}
