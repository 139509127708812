import {StringTransformer} from './string-transformer';
import {SectionPageComponent} from "./section-page-component";

export class CourseListPageComponent extends SectionPageComponent<
  CourseListPageComponent,
  'courseList'
> {
  public allowOverrideThroughPageContext: boolean;
  public courses: string[];

  constructor(el: any = {}) {
    super(
      el,
      'courseList',
      {hasTitle: true, required: true},
      {hasText: false, required: false},
    );
    this.allowOverrideThroughPageContext = el.allowOverrideThroughPageContext ?? false;
    this.courses = el.courses || [];
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      allowOverrideThroughPageContext: this.allowOverrideThroughPageContext,
      courses: this.courses,
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): CourseListPageComponent {
    const res = new CourseListPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
