import {BaseWebsiteResource} from "./base-website-resource";
import {Menu} from "./menu";

export class SpecialPages extends BaseWebsiteResource {

  public agbPage: Menu | null;

  constructor(el: any = {}) {
    super(el);

    this.agbPage = el.agbPage ? new Menu(el.agbPage) : null;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),

      agbPage: this.agbPage?.toJson(),
    };
  }

  public override clone(): SpecialPages {
    return new SpecialPages({...this.toJson(), id: undefined});
  }
}
