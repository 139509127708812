import {Component, OnInit} from '@angular/core';
import {Menu, MenuItem} from 'brynui';
import {OAuthService} from 'angular-oauth2-oidc';
import {authCodeFlowConfig} from './shared/oauthConfig';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatestWith, filter, timer,} from 'rxjs';
import {globalWebsite, Website} from '@shared/model';
import {WebsiteService} from './shared/services/website.service';
import {UserApiService} from './shared/services/user-api.service';
import {userAllowedToViewPage,} from '../guards/permission-guard';
import {environment} from "@environments/environment";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
    public canSwitchWebsites = false;
    public canPublish = false;
    public website: Website | undefined;
    public websites: Website[] = [];
    public websitePublishing: boolean = false;
    public user: { email: string; name: string } = {email: '', name: 'Academy'};
    public accountActions = [{name: 'Abmelden', cb: () => this.logout()}];
    public menu: Menu | undefined = undefined;
    private privileges: any[] = [];

    private userDataLoaded: boolean = false;

    constructor(
        private router: Router,
        private oauthService: OAuthService,
        private websiteService: WebsiteService,
        private activatedRoute: ActivatedRoute,
        private userService: UserApiService,
    ) {

        // Redirect to initially requested page
        this.oauthService.events.subscribe(event => {
            if (event.type === 'token_received' && this.oauthService.state) {
                const redirect_uri = decodeURIComponent(this.oauthService.state);
                if (redirect_uri && redirect_uri !== '/') {
                    this.router.navigateByUrl(redirect_uri);
                }
            }
        });

        // Remember the selected configuration
        this.oauthService.configure(authCodeFlowConfig);
        this.oauthService.setupAutomaticSilentRefresh();

        // Automatically load user profile
        this.oauthService.events
            .pipe(
                filter(e => {
                    return e.type === 'token_received';
                }),
            )
            .subscribe(_ => {
                this.onAuthenticated();
            });

        this.oauthService.events
            .pipe(
                filter(e => {
                    return e.type === 'token_refresh_error';
                }),
            )
            .subscribe(tokenRefreshError => {
                this.oauthService.refreshToken();
            });


        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
            if (
                !this.oauthService.hasValidIdToken() ||
                !this.oauthService.hasValidAccessToken()
            ) {
                const request_uri = window.location.pathname + window.location.search;
                const additionalState = (request_uri !== '/' ? request_uri : undefined);
                this.oauthService.initLoginFlow(additionalState);
            } else {
                this.onAuthenticated();
            }
        });


        this.userService.getPrivileges().subscribe(privileges => {
            this.privileges = privileges;
            this.updateMenu();
        });

        this.userService.getUser().subscribe(user => {
            this.website = user.website;
            this.user = {email: user.email, name: user.name};

            this.canSwitchWebsites = user.canSwitchWebsites;
            this.canPublish = user.canPublish;
            this.isEditingGlobalWebsite = user.websiteId === null;



            this.activatedRoute.queryParams.subscribe(params => {
                const {property, ...rest} = params

                if (!property) {
                    return
                }
                if (user.website?.id === property) {return}

                this.setWebsite(property);

                this.router.navigate([], { queryParams: rest });
            });

        });
    }

    private _isEditingGlobalWebsite = false;

    get isEditingGlobalWebsite(): boolean {
        return this._isEditingGlobalWebsite;
    }

    set isEditingGlobalWebsite(value: boolean) {
        this._isEditingGlobalWebsite = value;
        this.updateMenu();
    }

    public logout() {
        this.oauthService.logOut();
    }

    public publishWebsite(ev: any) {
        ev.preventDefault();

        this.websitePublishing = true;

        this.websiteService
            .publishPreviews()
            .pipe(combineLatestWith(timer(1500)))
            .subscribe(() => {
                this.websitePublishing = false;
            });
    }

    public websiteToLabel(website: Website): string {
        return website.name;
    }

    public setWebsite(websiteId: string | undefined): void {
        this.userService.setUserWebsite(websiteId ?? null).subscribe(res => {
            location.reload();
        });
    }


    ngOnInit(): void {
    }

    previewWebsite() {
        window.open(`https://${this.website?.domain}/api/draft?secret=${environment.draftSecret}`, '_blank');
    }

    private onAuthenticated(): void {
        if (this.userDataLoaded) {
            return;
        }
        this.userDataLoaded = true;
        this.userService.loadUser();
        this.websiteService
            .listAll()
            .subscribe(
                websites => (this.websites = [globalWebsite, ...websites]),
            );
    }

    private updateMenu(): void {
        this.menu = undefined;
        setTimeout(() => {
            this.menu = Menu.fromItems([
                new MenuItem({
                    title: 'Dashboard',
                    routerLink: '/',
                    disabled: !userAllowedToViewPage(
                        '/',
                        this.privileges,
                        this.isEditingGlobalWebsite,
                    ),
                }),
                ...(this.isEditingGlobalWebsite
                    ? [
                        new MenuItem({
                            title: 'Websites',
                            routerLink: '/website',
                            activePathRegex: /^\/website(\/[^\/]+)?$/,
                            disabled: !userAllowedToViewPage(
                                '/website',
                                this.privileges,
                                this.isEditingGlobalWebsite,
                            ),
                        }),
                    ]
                    : []),
                new MenuItem({
                    title: 'Nutzer',
                    routerLink: '/user',
                    activePathRegex: /^\/user(\/[^\/]+)?$/,
                    disabled: !userAllowedToViewPage(
                        '/user',
                        this.privileges,
                        this.isEditingGlobalWebsite,
                    ),
                }),
                ...(!this.isEditingGlobalWebsite
                    ? [
                        new MenuItem({
                            title: 'Konfiguration',
                            children: [
                                new MenuItem({
                                    title: 'Stammdaten',
                                    routerLink: '/website-master-data',
                                    activePathRegex: /^\/website-master-data(\/[^\/]+)?$/,
                                    disabled: !userAllowedToViewPage(
                                        '/website-master-data',
                                        this.privileges,
                                        this.isEditingGlobalWebsite,
                                    ),
                                }),
                                new MenuItem({
                                    title: 'USPs',
                                    routerLink: '/usps',
                                    activePathRegex: /^\/usps(\/[^\/]+)?$/,
                                    disabled: !userAllowedToViewPage(
                                        '/usps',
                                        this.privileges,
                                        this.isEditingGlobalWebsite,
                                    ),
                                }),
                                new MenuItem({
                                    title: 'Führerscheinkonfigurator',
                                    routerLink: '/registration-config',
                                    activePathRegex: /^\/registration-config(\/[^\/]+)?$/,
                                    disabled: !userAllowedToViewPage(
                                        '/registration-config',
                                        this.privileges,
                                        this.isEditingGlobalWebsite,
                                    ),
                                }),
                                new MenuItem({
                                    title: 'Kurs-Einstellungen',
                                    routerLink: '/course-config',
                                    activePathRegex: /^\/course-config(\/[^\/]+)?$/,
                                    disabled: !userAllowedToViewPage(
                                        '/course-config',
                                        this.privileges,
                                        this.isEditingGlobalWebsite,
                                    ),
                                }),
                              new MenuItem({
                                title: 'Spezialseiten',
                                routerLink: '/special-page',
                                activePathRegex: /^\/special-page(\/[^\/]+)?$/,
                                disabled: !userAllowedToViewPage(
                                  '/special-page',
                                  this.privileges,
                                  this.isEditingGlobalWebsite,
                                ),
                              }),
                            ],
                        }),
                    ]
                    : []),

                new MenuItem({
                    title: 'Seiten',
                    routerLink: '/menu',
                    activePathRegex: /^\/(menu|page)(\/[^\/]+)?$/,
                    disabled: !userAllowedToViewPage(
                        '/menu',
                        this.privileges,
                        this.isEditingGlobalWebsite,
                    ),
                }),
                new MenuItem({
                    title: 'Klassen & Kurse',
                    children: [
                        new MenuItem({
                            title: 'Führerscheinklasse',
                            routerLink: '/license-class',
                            activePathRegex: /^\/license-class(\/[^\/]+)?$/,
                            disabled: !userAllowedToViewPage(
                                '/license-class',
                                this.privileges,
                                this.isEditingGlobalWebsite,
                            ),
                        }),
                        new MenuItem({
                            title: 'Klassen-Kategorien',
                            routerLink: '/license-class-category',
                            activePathRegex: /^\/license-class-category(\/[^\/]+)?$/,
                            disabled: !userAllowedToViewPage(
                                '/license-class-category',
                                this.privileges,
                                this.isEditingGlobalWebsite,
                            ),
                        }),
                        new MenuItem({
                            title: 'Kurse',
                            routerLink: '/course',
                            activePathRegex: /^\/course(\/[.]+)?$/,
                            disabled: !userAllowedToViewPage(
                                '/course',
                                this.privileges,
                                this.isEditingGlobalWebsite,
                            ),
                        }),
                    ],
                }),
                new MenuItem({
                    title: 'Weitere Inhalte',
                    children: [
                        ...(!this.isEditingGlobalWebsite
                            ? [
                                new MenuItem({
                                    title: 'Team',
                                    routerLink: '/team-member',
                                    activePathRegex: /^\/team-member(\/[^\/]+)?$/,
                                    disabled: !userAllowedToViewPage(
                                        '/team-member',
                                        this.privileges,
                                        this.isEditingGlobalWebsite,
                                    ),
                                }),
                                new MenuItem({
                                    title: 'Standorte',
                                    routerLink: '/location',
                                    activePathRegex: /^\/location(\/[^\/]+)?$/,
                                    disabled: !userAllowedToViewPage(
                                        '/location',
                                        this.privileges,
                                        this.isEditingGlobalWebsite,
                                    ),
                                }),
                                new MenuItem({
                                    title: 'Fahrzeuge',
                                    routerLink: '/vehicle',
                                    activePathRegex: /^\/vehicle(\/[^\/]+)?$/,
                                    disabled: !userAllowedToViewPage(
                                        '/vehicle',
                                        this.privileges,
                                        this.isEditingGlobalWebsite,
                                    ),
                                }),
                            ]
                            : []),

                        new MenuItem({
                            title: 'Partner',
                            routerLink: '/partner',
                            activePathRegex: /^\/partner(\/[^\/]+)?$/,
                            disabled: !userAllowedToViewPage(
                                '/partner',
                                this.privileges,
                                this.isEditingGlobalWebsite,
                            ),
                        }),
                        new MenuItem({
                            title: 'News',
                            routerLink: '/news',
                            activePathRegex: /^\/news(\/[^\/]+)?$/,
                            disabled: !userAllowedToViewPage(
                                '/news',
                                this.privileges,
                                this.isEditingGlobalWebsite,
                            ),
                        }),
                        new MenuItem({
                            title: 'Blog Posts',
                            routerLink: '/blog-post',
                            activePathRegex: /^\/blog-post(\/[^\/]+)?$/,
                            disabled: !userAllowedToViewPage(
                                '/blog-post',
                                this.privileges,
                                this.isEditingGlobalWebsite,
                            ),
                        }),
                    ],
                }),

                new MenuItem({
                    title: 'Hilfe & Mehr',
                    children: [
                        new MenuItem({
                            title: 'Assets zum Download',
                            routerLink: '/downloads',
                            activePathRegex: /^\/downloads(\/[^\/]+)?$/,
                        }),
                        new MenuItem({
                            title: 'Hilfe',
                            routerLink: '/support',
                            activePathRegex: /^\/support(\/[^\/]+)?$/,
                        }),
                    ],
                }),
            ]);
        });
    }
}

