import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class TablePageComponentRowButton {
  public style: 'primary' | 'secondary';
  public title: string;
  public externalLink: string;
  constructor(el: any = {}) {
    this.style = el.style;
    this.title = el.title;
    this.externalLink = el.externalLink;
  }

  public applyStringTransformer(transformer: StringTransformer) {
    const res = new TablePageComponentRowButton(this);

    res.title = transformer.transform(this.title);

    return res;
  }
}

export class TablePageComponentRow {
  public cells: string[];
  public button?: TablePageComponentRowButton;

  constructor(el: any = {}) {
    this.cells = el.cells || [];
    this.button = new TablePageComponentRowButton(el.button || {});
  }

  public applyStringTransformer(transformer: StringTransformer) {
    const res = new TablePageComponentRow(this);

    res.cells = this.cells.map(cell => transformer.transform(cell));
    res.button = this.button?.applyStringTransformer(transformer);

    return res;
  }
}

export class TablePageComponent extends SectionPageComponent<
  TablePageComponent,
  'table'
> {
  public header: string[] = [];
  public rows: TablePageComponentRow[] = [];

  constructor(el: any = {}) {
    super(
      el,
      'table',
      {hasTitle: true, required: true},
      {hasText: true, required: false},
    );

    this.header = el.header || [];
    this.rows = (el.rows || []).map((r: TablePageComponentRow) => {
      return new TablePageComponentRow(r);
    });

    this.rows.map(row => {
      if (row.cells.length < this.header.length) {
        for (let i = 0; i < this.header.length - row.cells.length; i++) {
          row.cells.push("");
        }
      } else if (row.cells.length > this.header.length) {
        for (let i = 0; i < row.cells.length - this.header.length; i++) {
          this.header.push("");
        }
      }
    });
  }

  public override getSearchKeys(): string[] {
    return [
      'sectionTitle',
      'sectionText.plainText',
      'rows.header',
      'rows.rows.cells',
      'rows.rows.button.title',
    ];
  }

  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new TablePageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};
    res.rows = this.rows.map(row => row.applyStringTransformer(transformer));

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      rows: this.rows,
      header: this.header,
    };
  }
}
